<template>
    <section>
        <modal ref="modalActivarProyectos" :titulo="estado==1? 'Desactivar Proyectos':'Activar Proyectos'" :icon="estado==1?'close (-1':'check'" @guardar="guardar">
            <div class="row mx-0">
                <div class="col-12">
                    <p class="f-15 text-muted2 text-center mb-3 mt-3">
                        {{ estado==1? '¿Desea desactivar este Proyecto?':'¿Desea activar este proyecto?'}}
                    </p>
                </div>
                <div v-if="estado==1" class="col-12 mb-3">
                    <p class="text-muted2 -f15 text-center">
                        El administrador del proyecto y sus tiendas aliadas no tendrán acceso al sistema
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Proyectos from '~/services/proyectos/proyectos'
import {mapGetters} from 'vuex'

export default {
    data(){
        return{
            model:{
                id: null,
            },
            estado: 1
        }
    },
    computed:{
        ...mapGetters({
            detalle: 'proyectos/proyectos/detalle'
        })
    },
    methods: {
        toggle(info){
            this.estado = info.estado
            this.model.id = info.id
            this.$refs.modalActivarProyectos.toggle();
        },
        async guardar(){
            try {
                this.model.estado = this.estado
                const {data} = await Proyectos.activarInactivarProyecto(this.model)
                this.$store.commit('proyectos/proyectos/set_detalle', {...this.detalle, estado: data.estado})
                this.notificacion('Mensaje', 'Estado cambiado', 'success')
                this.$refs.modalActivarProyectos.toggle();
                this.$emit('estado')
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>